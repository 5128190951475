<template>
 logout
</template>

<script>
import BackendModel from "../../models/BackendModel";
import('./point/css/style.css')
export default {
  name: 'PointLogout',
  data() {
    return {
        id :''
    }
  },
   async mounted () {
    let backendModel = new BackendModel()
        let name = 'point_session_id'
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        let session_id = parts.pop().split(';').shift()
        console.log(session_id)
        await backendModel.point_Request("/Api/service/backoffice/point_logout",  { session_id})  
        await this.$router.push({ path: '/point'})
   },
 
}
</script>

<style>
@import 'point/css/style.css';
</style>
